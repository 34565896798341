import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { PusherProvider } from './contexts/PusherContext'; // Import the PusherProvider
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppLoading from './components/misc/AppLoading';
import Navigation from './components/Navigation';
import FunnelDashboard from './components/FunnelDashboard';
import AdvancedMode from './components/funnel/AdvancedMode';
import Funnel from './components/Funnel';
import Dashboard from './components/Dashboard';
import NewDashboard from './components/NewDashboard';
import Agent from './components/Agent';
import GoLogin from './components/GoLogin';
import CreateCompany from './components/CreateCompany';
import Settings from './components/Settings';
import Profiles from './components/Profiles';
import Profile from './components/profiles/Profile';
import Emails from './components/Emails';
import EditSequence from './components/emails/EditSequence';
import IntegrationsComplete from './components/settings/IntegrationsComplete';
import Product from './components/products/Product';
import Products from './components/Products';
import Debug from './components/Debug';
import KnownOrg from './components/analyst/KnownOrg';
import Prototype from './components/Prototype';
import api from './api';

import EmailOAgentProspect from './components/prospects/EmailOAgentProspect';
import Analyst from './components/Analyst';
import Prospect from './components/Prospect';

import Campaigns from './components/Campaigns';
import Campaign from './components/campaigns/Campaign';
import CampaignWizard from './components/campaigns/CampaignWizard';
import CampaignEditMessaging from './components/campaigns/CampaignEditMessaging';
import CampaignEditProspects from './components/campaigns/CampaignEditProspects';
import CampaignEditCompanies from './components/campaigns/CampaignEditCompanies';
import CampaignEditSettings from './components/campaigns/CampaignEditSettings';

import TaskListPage from './components/tasks/TaskListPage';
import ManageCampaign from './components/campaigns/ManageCampaign';

import { useSidebar } from './contexts/SidebarContext';

import StatusCheckWrapper from './components/misc/StatusCheckWrapper';
import './index.scss';

function App() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [hasCompany, setHasCompany] = useState(null);

  const { isSidebarVisible, imposedWarnings } = useSidebar();
  const navigate = useNavigate();

  useEffect(() => {
    const checkCompanyStatus = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          const response = await api.get('/confirm-user-company', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setHasCompany(response.data.has_company);

          if (!response.data.has_company) {
            navigate('/create-company');
          }
        } catch (error) {
          console.error('Error checking company status:', error);
        }
      }
    };

    checkCompanyStatus();
  }, [isAuthenticated, navigate, getAccessTokenSilently]);

  if (!isLoading && !isAuthenticated) {
    return <GoLogin />;
  }

  if (isAuthenticated && hasCompany !== null) {
    return (
      <PusherProvider>
        <div className='global-container'>
          <div
            id={`sidebar-wrapper`}
            className={`${!isSidebarVisible ? 'hidden' : 'visible'}`}
          >
            <Navigation
              isVisible={isSidebarVisible}
              imposedWarnings={imposedWarnings}
            />
          </div>
          <div id='page-content-wrapper'>
            <Routes>
              <Route exact path='/' element={<NewDashboard />} />
              <Route path='/dashboard' element={<NewDashboard />} />
              <Route path='/old-dashboard' element={<Dashboard />} />
              <Route path='/task-list' element={<TaskListPage />} />
              <Route path='/task-list/:campaignId' element={<TaskListPage />} />
              <Route path='/funnel' element={<FunnelDashboard />} />
              <Route path='/advanced-mode' element={<AdvancedMode />} />
              <Route path='/campaigns' element={<Campaigns />} />
              <Route path='/campaign-edit/:id' element={<ManageCampaign />} />
              <Route
                path='/campaign-edit/:id/:step'
                element={<ManageCampaign />}
              />
              <Route path='/campaign/:id' element={<Campaign />} />
              <Route
                path='/campaign/:id/messaging'
                element={<CampaignEditMessaging />}
              />
              <Route
                path='/campaign-prospects/:id'
                element={<CampaignEditProspects />}
              />
              <Route
                path='/campaign-companies/:id'
                element={<CampaignEditCompanies />}
              />
              <Route
                path='/campaign-settings/:id'
                element={<CampaignEditSettings />}
              />
              <Route path='/campaign-wizard/:id' element={<CampaignWizard />} />
              <Route path='/prospect/:id' element={<Prospect />} />
              <Route
                path='/oagent-prospect'
                element={<EmailOAgentProspect />}
              />
              <Route path='/profiles' element={<Profiles />} />
              <Route path='/profiles/:id' element={<Profile />} />
              <Route path='/funnel/:id' element={<Funnel />} />
              <Route path='/agent/:id' element={<Agent />} />
              <Route path='/settings' element={<Settings />} />
              <Route path='/settings/:setting' element={<Settings />} />
              <Route path='/create-company' element={<CreateCompany />} />
              <Route path='/emails' element={<Emails />} />
              <Route
                path='/emails/:sequenceId/edit'
                element={<EditSequence />}
              />
              <Route
                path='/integrations-complete'
                element={<IntegrationsComplete />}
              />
              <Route path='/products' element={<Products />} />
              <Route path='/products/:id' element={<Product />} />
              <Route path='/marauders' element={<Debug />} />
              <Route path='/analyst' element={<Analyst />} />
              <Route path='/analyst/:id' element={<KnownOrg />} />
              <Route path='/prototype' element={<Prototype />} />
            </Routes>
          </div>
          <ToastContainer className='toast-container-wrapper' />
          <StatusCheckWrapper />
        </div>
      </PusherProvider>
    );
  }

  return <AppLoading />;
}

export default App;
