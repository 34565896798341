import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ConfirmModal({
  show,
  handleClose,
  handleConfirm,
  singleButton,
  title,
  bodyText,
  confirmLabel,
  verticalButtons,
  cancelLabel,
  confirmDisabled,
  littleSweetieVersion,
  confirmFirst,
  ...rest
}) {
  return (
    <Modal show={show} onHide={handleClose} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <div
          className={`confirm-modal-button-wrapper ${verticalButtons ? 'vertical' : ''} ${littleSweetieVersion ? 'little-sweetie' : ''}`}
        >
          {confirmFirst && (
            <Button
              variant='primary'
              onClick={handleConfirm}
              disbled={confirmDisabled}
            >
              {confirmLabel || 'Confirm'}
            </Button>
          )}
          {!singleButton && (
            <Button variant='outline-primary' onClick={handleClose}>
              {cancelLabel || 'Cancel'}
            </Button>
          )}
          {!confirmFirst && (
            <Button
              variant='primary'
              onClick={handleConfirm}
              disbled={confirmDisabled}
            >
              {confirmLabel || 'Confirm'}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
