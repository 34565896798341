import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import RangeSlider from 'react-bootstrap-range-slider';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import DreamInfoExpander from '../misc/DreamInfoExpander';
import { ReactComponent as ResearchPointIcon } from '../../icons/research-point.svg';
import { ReactComponent as MagicWandIcon } from '../../icons/magic-wand.svg';

import GoogleIcon from '../../icons/google.png';
import LinkedInIcon from '../../icons/linkedin.png';
import OpenAIIcon from '../../icons/openai.png';
import CrunchbaseIcon from '../../icons/crunchbase.png';

import DreamSideModalLoader from '../misc/DreamSideModalLoader';
import { predefinedResearchPoints } from '../../utils/manifests/research_point';

const ResearchPointModal = ({ show, handleClose, type, presetName = '' }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [step, setStep] = useState(1);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [additionalOptions, setAdditionalOptions] = useState({});
  const [name, setName] = useState(presetName);
  const [timeMeasure, setTimeMeasure] = useState('anytime');
  const [timeValue, setTimeValue] = useState('');
  const [definition, setDefinition] = useState('');
  const [additionalContext, setAdditionalContext] = useState('');
  const [example, setExample] = useState('');
  const [researchItems, setResearchItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [maxGoogleSearchItems, setMaxGoogleSearchItems] = useState(1);
  const [totalKnownOrgs, setTotalKnownOrgs] = useState(0);

  useEffect(() => {
    setName(presetName);
  }, [presetName]);

  useEffect(() => {
    const fetchKnownOrgs = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/analyst/known-orgs', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTotalKnownOrgs(response.data.totalItems);
      } catch (error) {
        console.error('Error fetching known orgs:', error);
      }
    };

    if (show) {
      fetchKnownOrgs();
    }
  }, [show, getAccessTokenSilently]);

  const handleNext = async (opts = {}) => {
    const finalName =
      opts.name ||
      (typeof name === 'function' ? name(additionalOptions) : name);
    const finalDefinition =
      opts.definition ||
      (typeof definition === 'function'
        ? definition(additionalOptions)
        : definition);

    const finalTimeValue =
      opts.time_value ||
      (typeof timeValue === 'function'
        ? timeValue(additionalOptions)
        : timeValue);

    const finalTimeMeasure =
      opts.time_measure ||
      (typeof timeMeasure === 'function'
        ? timeMeasure(additionalOptions)
        : timeMeasure);

    const finalExample = opts.example || example;

    setTimeValue(finalTimeValue);
    setTimeMeasure(finalTimeMeasure);
    setName(finalName);
    setDefinition(finalDefinition);
    setIsLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/analyst/generate-research-items',
        {
          name: finalName,
          definition: finalDefinition,
          additional_context: additionalContext,
          example: finalExample,
          use_time_check:
            finalTimeMeasure === 'anytime' || !finalTimeMeasure ? false : true,
          time_value: finalTimeValue,
          time_meausre: finalTimeMeasure,
          type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setResearchItems(response.data);
      setStep(3);
    } catch (error) {
      console.error('Error generating research items:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async () => {
    setIsCreating(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/analyst/research-points',
        {
          name: typeof name === 'function' ? name(additionalOptions) : name,
          definition:
            typeof definition === 'function'
              ? definition(additionalOptions)
              : definition,
          additional_context: additionalContext,
          type,
          example,
          research_items: researchItems.slice(0, maxGoogleSearchItems),
          use_time_check: timeMeasure === 'anytime' ? false : true,
          time_measure: timeMeasure === 'anytime' ? null : timeMeasure,
          time_value: timeValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      handleClose(response.data.id);
    } catch (error) {
      console.error('Error creating research point:', error);
      setStep(selectedTemplate ? 2 : 1); // Go back to step if there is an error
    } finally {
      setIsCreating(false);
    }
  };

  const handleOptionChange = (option, value) => {
    setAdditionalOptions({
      ...additionalOptions,
      [option]: value,
    });
  };

  const calcTotalCredits = () => {
    let total = 0;
    let googleSearchCount = 0;

    researchItems.forEach((item) => {
      if (item.method === 'google_search') {
        if (googleSearchCount < maxGoogleSearchItems) {
          total += 0.15;
          googleSearchCount++;
        }
      } else if (item.method === 'linkedin_employees_with_title_count') {
        total += 0.5;
      } else if (item.method.startsWith('linkedin_')) {
        total += 2;
      } else if (item.method === 'connector_match') {
        total += 5;
      } else {
        total += 1;
      }
    });

    return Math.ceil(total);
  };

  const calcMethodName = () => {
    const polite = {
      google_search: 'Internet Search (Google + LLMs)',
      linkedin_current_job_listings: 'Job Listings (LinkedIn)',
      linkedin_historical_job_listings: 'Historical Job Listings (LinkedIn)',
      linkedin_current_employees: 'Employee Search (LinkedIn + Google)',
      linkedin_employees_with_title_count: 'Employee Count (LinkedIn)',
      scraped_data: 'Web data + LLM',
      hotel_details: 'Web data + LLM',
      hotel_booking_engine: 'Web data + LLM',
      raw_data: 'LinkedIn Data',
      ab_test: 'Random Values',
      own_knowledge: 'Agent Knowledge (LLM)',
      connector_match: 'Connection Match (LinkedIn + LLM)',
      known_hotel_technology: 'Hotel Technology Match',
      social_media_handle: 'Social Media Search',
    };
    if (researchItems.length === 1) return polite[researchItems[0].method];
    const allMethodsSame = researchItems.every(
      (item) => item.method === researchItems[0].method,
    );
    return allMethodsSame
      ? polite[researchItems[0].method]
      : 'Multiple methods';
  };

  const renderDetailArea = () => {
    if (researchItems.length === 0) return null;

    const item = researchItems[0];

    if (item.method === 'own_knowledge') {
      return null;
    } else if (item.method === 'raw_data') {
      return (
        <p>Agent will interpret data it has on file from the initial sync.</p>
      );
    } else if (
      item.method === 'scraped_data' ||
      item.method === 'hotel_details' ||
      item.method === 'hotel_booking_engine'
    ) {
      return (
        <p>
          Agent will find the answer on the web and use LLMs to validate it.
        </p>
      );
    } else if (item.method === 'ab_test') {
      return <p>Agent will randomly assign data point values.</p>;
    } else if (item.method === 'google_search') {
      return (
        <p>
          Agent will build searches that answer the following questions:
          <ul className='google-questions-line'>
            {researchItems
              .slice(0, maxGoogleSearchItems)
              .map((searchItem, index) => {
                return <li key={index}>{searchItem.metadata.question}</li>;
              })}
          </ul>
        </p>
      );
    } else if (item.method === 'linkedin_current_job_listings') {
      return (
        <p>
          Agent will retrieve current job listing's for{' '}
          {item.metadata.job_titles.join(', ') || 'all roles'} and then ask the
          question <b>"{item.metadata.question}"</b>
        </p>
      );
    } else if (item.method === 'linkedin_historical_job_listings') {
      return (
        <p>
          Agent will retrieve job listings in the past six months for{' '}
          {item.metadata.job_titles.join(', ') || 'all roles'} and then ask the
          question <b>"{item.metadata.question}"</b>
        </p>
      );
    } else if (item.method === 'linkedin_current_employees') {
      return (
        <p>
          Agent will find the company's{' '}
          {item.metadata.explicit_job_title || 'relevant person'} and ask the
          question <b>"{item.metadata.question}"</b>
        </p>
      );
    } else if (item.method === 'connector_match') {
      return (
        <p>
          Agent will look through company employees based on guidance and find
          people your connectors know.
        </p>
      );
    } else if (item.method === 'known_hotel_technology') {
      return (
        <p>
          Agent will review the hotels website, search Google and use it's own
          knowledge to determine what technology it's using.
        </p>
      );
    } else if (item.method === 'linkedin_employees_with_title_count') {
      return (
        <p>
          Agent will find search through LinkedIn for matches and respond with
          the number.
        </p>
      );
    } else if (item.method === 'social_media_handle') {
      return (
        <p>
          Agent will search the internet for the social media handle of the
          company.
        </p>
      );
    }
  };

  const isInALoadingState = isLoading || isCreating;

  const sourceIcons = {
    google: <img src={GoogleIcon} alt='Google icon' />,
    openai: <img src={OpenAIIcon} alt='OpenAI icon' />,
    linkedin: <img src={LinkedInIcon} alt='OpenAI icon' />,
    crunchbase: <img src={CrunchbaseIcon} alt='Crunchbase icon' />,
  };

  const sourceText = {
    google: 'Google',
    openai: 'LLMs',
    linkedin: 'LinkedIn',
    crunchbase: 'Crunchbase',
  };

  return (
    <DreamSideModal show={show} handleClose={handleClose} position='right'>
      <DreamModalInner>
        {isLoading && (
          <DreamSideModalLoader>Creating Research Plan</DreamSideModalLoader>
        )}
        {isCreating && (
          <DreamSideModalLoader>
            Creating{' '}
            {type === 'campaign_trigger' ? 'Campaign Trigger' : 'Datapoint'}
          </DreamSideModalLoader>
        )}
        {!isInALoadingState && step === 1 && (
          <>
            <DreamModalInner.Header>
              <div className='red-icon'>
                <ResearchPointIcon />
              </div>
              Available research
            </DreamModalInner.Header>
            <DreamModalInner.Body>
              <div className='research-point-side-wrapper template-selector'>
                <div>
                  <h5>Recommended for you</h5>
                  <div className='template-list'>
                    {predefinedResearchPoints
                      .filter((point) => point.type === type)
                      .map((template, index) => (
                        <div
                          key={index}
                          className='template'
                          onClick={() => {
                            setSelectedTemplate(template);
                            setAdditionalOptions({});
                            if (template.additional_options) {
                              setName(() => template.prefill.name);
                              setDefinition(() => template.prefill.definition);
                              setExample(() => template.prefill.example);
                              setTimeValue(() => template.prefill.time_value);
                              setTimeMeasure(
                                () => template.prefill.time_measure,
                              );
                              setStep(2);
                            } else {
                              setName(template.prefill.name);
                              setDefinition(template.prefill.definition);
                              setExample(template.prefill.example);
                              setTimeValue(template.prefill.time_value);
                              setTimeMeasure(template.prefill.time_measure);
                              handleNext({
                                name: template.prefill.name,
                                definition: template.prefill.definition,
                                example: template.prefill.example,
                                time_value: template.prefill.time_value,
                                time_measure: template.prefill.time_measure,
                              });
                            }
                          }}
                        >
                          <div className='template-left'>
                            {template.name}
                            {template.sources &&
                              template.sources.length > 0 && (
                                <div className='sources-line'>
                                  <span>Sources:</span>
                                  {template.sources.map((x) => {
                                    return (
                                      <div className='source' key={x}>
                                        {sourceIcons[x]}
                                        {sourceText[x]}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                          </div>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                      ))}
                  </div>
                </div>
                <div>
                  <h5>Create your own research</h5>
                  <Button
                    className='setup-prompts-button'
                    onClick={() => setStep(2)}
                  >
                    <MagicWandIcon />
                    Setup prompts for agent
                  </Button>
                </div>
              </div>
            </DreamModalInner.Body>
            <DreamModalInner.Footer>
              <span></span>
              <Button variant='outline-primary' onClick={handleClose}>
                Cancel
              </Button>
            </DreamModalInner.Footer>
          </>
        )}
        {!isInALoadingState &&
          step === 2 &&
          selectedTemplate?.additional_options && (
            <>
              <DreamModalInner.Header>
                <div className='red-icon'>
                  <ResearchPointIcon />
                </div>
                Extra Information
              </DreamModalInner.Header>
              <DreamModalInner.Body>
                <div>
                  <p className='intro-description'>
                    We need some extra details to fill out your information.
                  </p>
                  <div className='research-point-side-wrapper dream-side-modal-form'>
                    {Object.entries(selectedTemplate.additional_options).map(
                      ([key, option], index) => (
                        <Form.Group key={index}>
                          <Form.Label>{option.description}</Form.Label>
                          {option.long_description && (
                            <Form.Text>{option.long_description}</Form.Text>
                          )}
                          <Form.Control
                            type={option.input_type}
                            value={additionalOptions[key] || ''}
                            onChange={(e) =>
                              handleOptionChange(key, e.target.value)
                            }
                            size='lg'
                            {...option}
                          />
                        </Form.Group>
                      ),
                    )}
                  </div>
                </div>
              </DreamModalInner.Body>
              <DreamModalInner.Footer>
                <div className='analyst'>
                  <Button
                    variant='primary'
                    onClick={() => handleNext({})}
                    disabled={isLoading}
                  >
                    Next <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </div>
                <Button
                  variant='outline-primary'
                  onClick={() => {
                    setSelectedTemplate(null);
                    setStep(1);
                  }}
                >
                  Back
                </Button>
              </DreamModalInner.Footer>
            </>
          )}
        {!isInALoadingState &&
          step === 2 &&
          !selectedTemplate?.additional_options && (
            <>
              <DreamModalInner.Header>
                <div className='red-icon'>
                  <ResearchPointIcon />
                </div>
                Add custom{' '}
                {type === 'campaign_trigger' ? 'Campaign Trigger' : 'Datapoint'}
              </DreamModalInner.Header>
              <DreamModalInner.Body>
                <div className='research-point-side-wrapper'>
                  <div className='dream-side-modal-form'>
                    <Form.Group>
                      <Form.Label>
                        {type === 'campaign_trigger'
                          ? 'Trigger name'
                          : 'Datapoint name'}
                      </Form.Label>
                      <Form.Text>
                        {type === 'campaign_trigger'
                          ? 'e.g. "New CTO hire"'
                          : 'e.g. "Key customers"'}
                      </Form.Text>
                      <Form.Control
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        {type === 'campaign_trigger'
                          ? 'Description'
                          : 'Description'}
                      </Form.Label>
                      <Form.Text>
                        {type === 'campaign_trigger'
                          ? 'e.g. "Should detect when a New CTO has been hired"'
                          : 'e.g. "An overview of five to ten recently announced customers"'}
                      </Form.Text>
                      <Form.Control
                        as='textarea'
                        value={definition}
                        onChange={(e) => setDefinition(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Relevance</Form.Label>
                      <Form.Text>
                        {type === 'campaign_trigger'
                          ? 'e.g. Newly hired CTOs are more likely to buy new penetration testing services like ours'
                          : 'e.g. "Allows us to reference existing customers for email personalization"'}
                      </Form.Text>
                      <Form.Control
                        as='textarea'
                        value={additionalContext}
                        onChange={(e) => setAdditionalContext(e.target.value)}
                        rows={3}
                      />
                    </Form.Group>

                    {type === 'datapoint' && (
                      <Form.Group>
                        <Form.Label>Example</Form.Label>
                        <Form.Text>
                          e.g. "Nike, Walmart, Brex, Doordash, LSEG"
                        </Form.Text>
                        <Form.Control
                          value={example}
                          onChange={(e) => setExample(e.target.value)}
                        />
                      </Form.Group>
                    )}
                    <>
                      <Form.Group>
                        <Form.Label>Timeframe</Form.Label>
                        <Form.Text>Include information/events from</Form.Text>
                        <Form.Select
                          value={timeMeasure}
                          onChange={(e) => setTimeMeasure(e.target.value)}
                        >
                          <option value='anytime'>Anytime</option>
                          <option value='before'>Before a certain date</option>
                          <option value='after'>After a certain date</option>
                        </Form.Select>
                      </Form.Group>

                      {timeMeasure !== 'anytime' && (
                        <Form.Group>
                          <Form.Label>Relevant Date</Form.Label>
                          <Form.Text>
                            The date to review information from/up-to
                          </Form.Text>
                          <Form.Control
                            type='date'
                            format='YYYY-MM-DD'
                            value={timeValue}
                            onChange={(e) => setTimeValue(e.target.value)}
                          />
                        </Form.Group>
                      )}
                    </>
                  </div>
                  <DreamInfoExpander
                    alwaysExpanded
                    heading={`What makes a good ${type === 'campaign_trigger' ? 'Trigger' : 'Datapoint'}`}
                  >
                    <p>
                      Analyst agents are currently trained to work with search
                      engines and professional network data.
                    </p>
                    {type === 'campaign_trigger' && (
                      <>
                        <p>
                          Triggers that work best are role based ("Are they
                          hiring for a new X", "Was their CxO hired in the last
                          six months?") and announcement based ("Have they
                          announced new market expansion", "Are they investing
                          in solar?").
                        </p>
                        <p>
                          Currently "growth" in department headcount is not well
                          supported.
                        </p>
                      </>
                    )}
                    {type === 'datapoint' && (
                      <>
                        <p>
                          Datapoints that work best are metrics that are
                          verifiable facts (e.g. "Have they won any design
                          awards?", "Do they have a presence in China?").
                        </p>
                        <p>
                          Datapoints that would require summation do{' '}
                          <b>are not well supported</b> (e.g. "Number of new
                          office announcements in 2024").
                        </p>
                        <p>
                          Datapoints related to hiring growth{' '}
                          <b>are not well supported</b> (e.g. "Increase in Sales
                          Hiring").
                        </p>
                      </>
                    )}
                  </DreamInfoExpander>
                </div>
              </DreamModalInner.Body>
              <DreamModalInner.Footer>
                <div className='analyst'>
                  <Button
                    variant='primary'
                    onClick={handleNext}
                    disabled={isLoading}
                  >
                    Next <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </div>
                <Button variant='outline-primary' onClick={() => setStep(1)}>
                  Back
                </Button>
              </DreamModalInner.Footer>
            </>
          )}
        {!isInALoadingState && step === 3 && (
          <>
            <DreamModalInner.Header>
              <div className='red-icon'>
                <ResearchPointIcon />
              </div>
              Create new{' '}
              {type === 'campaign_trigger' ? 'Campaign Trigger' : 'Datapoint'}
            </DreamModalInner.Header>
            <DreamModalInner.Body>
              <div className='research-point-side-wrapper'>
                <p className='top-definition'>{name}</p>
                <div>
                  {type === 'datapoint' &&
                    researchItems.some(
                      (item) => item.method === 'google_search',
                    ) && (
                      <div className='slider-wrapper'>
                        <p>
                          <b>Answer detail</b>
                        </p>
                        <RangeSlider
                          value={maxGoogleSearchItems}
                          min={1}
                          max={
                            researchItems.filter(
                              (item) => item.method === 'google_search',
                            ).length
                          }
                          onChange={(e) =>
                            setMaxGoogleSearchItems(e.target.value)
                          }
                          tooltip={false}
                          variant='info'
                        />
                        <div className='indicator-line'>
                          <span>
                            <b>Fast</b>
                          </span>
                          <span>
                            <b>Comprehensive</b>
                          </span>
                        </div>
                      </div>
                    )}
                  <div>
                    <div className='cost-area'>
                      <div className='area how-area'>
                        <p className='label'>Analyst will use</p>
                        <p className='value'>{calcMethodName()}</p>
                      </div>
                    </div>
                    <div className='detail-area'>
                      <p className='title'>What will your agent do?</p>
                      {renderDetailArea()}
                    </div>
                  </div>
                </div>
              </div>
            </DreamModalInner.Body>
            <DreamModalInner.Footer>
              <div className='analyst'>
                <Button
                  variant='primary'
                  onClick={handleCreate}
                  disabled={isLoading}
                >
                  Create <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
              <Button variant='outline-primary' onClick={() => setStep(2)}>
                Back
              </Button>
            </DreamModalInner.Footer>
          </>
        )}
      </DreamModalInner>
    </DreamSideModal>
  );
};

export default ResearchPointModal;
