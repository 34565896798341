import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import './campaigns-list.css';
import { Spinner, Overlay, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as WarningIcon } from '../../icons/warning-triangle.svg';
import { ReactComponent as HornIcon } from '../../icons/horn.svg';
import { ReactComponent as AutoProspectingIcon } from '../../icons/auto-prospecting.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error-exclamation.svg';

const CampaignsList = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState({ active: [], draft: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pacingLoading, setPacingLoading] = useState({});
  const [monthlyPacing, setMonthlyPacing] = useState({});
  const [campaignHealth, setCampaignHealth] = useState({});
  const [healthLoading, setHealthLoading] = useState({});
  const [expandedSections, setExpandedSections] = useState({
    active: true,
    draft: true,
  });
  const [showTooltip, setShowTooltip] = useState({});
  const tooltipTargets = useRef({});
  const [overallPacing, setOverallPacing] = useState(null);

  const fetchCampaignPacing = async (campaignId) => {
    try {
      setPacingLoading((prev) => ({ ...prev, [campaignId]: true }));
      const token = await getAccessTokenSilently();
      const response = await api.get(`/campaign-pacing/${campaignId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMonthlyPacing((prev) => ({
        ...prev,
        [campaignId]: response.data.monthlyPacing,
      }));
    } catch (error) {
      console.error(`Error fetching pacing for campaign ${campaignId}:`, error);
    } finally {
      setPacingLoading((prev) => ({ ...prev, [campaignId]: false }));
    }
  };

  const fetchCampaignHealth = async (campaignId) => {
    try {
      setHealthLoading((prev) => ({ ...prev, [campaignId]: true }));
      const token = await getAccessTokenSilently();
      const response = await api.get(`/campaign-pacing/${campaignId}/health`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCampaignHealth((prev) => ({
        ...prev,
        [campaignId]: {
          ...response.data,
          funnels: response.data.funnels || [],
        },
      }));
    } catch (error) {
      console.error('Error fetching campaign health:', error);
    } finally {
      setHealthLoading((prev) => ({ ...prev, [campaignId]: false }));
    }
  };

  const fetchOverallPacing = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/campaign-pacing/overall', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOverallPacing(response.data);
    } catch (error) {
      console.error('Error fetching overall pacing:', error);
    }
  };

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        setLoading(true);
        setError(false);
        const token = await getAccessTokenSilently();

        const response = await api.get('/oagents', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Filter out archived campaigns and split into active/draft
        const filteredCampaigns = response.data.filter(
          (campaign) => !campaign.archived,
        );

        const active = filteredCampaigns
          .filter((campaign) => !campaign.draft && campaign.autopilot)
          .sort((a, b) => b.id - a.id);
        const draft = filteredCampaigns
          .filter((campaign) => campaign.draft)
          .sort((a, b) => b.id - a.id);

        setCampaigns({ active, draft });
        setLoading(false);

        // Fetch pacing and health data for active campaigns
        active.forEach((campaign) => {
          fetchCampaignPacing(campaign.id);
          fetchCampaignHealth(campaign.id);
        });
      } catch (error) {
        console.error('Error fetching campaigns:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaigns();
    fetchOverallPacing();
  }, [getAccessTokenSilently]);

  const getHealthClass = (campaignId) => {
    const health = campaignHealth[campaignId];
    if (
      !health?.approved_email_health?.health ||
      !health?.prospects_health?.health
    )
      return '';

    if (
      health.approved_email_health.health === 'RED' ||
      health.prospects_health.health === 'RED'
    ) {
      return 'health-red';
    }
    if (health.approved_email_health.health === 'AMBER') {
      return 'health-amber';
    }
    return 'health-green';
  };

  const getEmailHealthText = (health) => {
    if (!health?.approved_email_health?.state) return '';

    const state =
      health.approved_email_health.state === 'AUTO_APPROVE_ON'
        ? 'Auto-Approve'
        : 'Manual Approve';
    const days = health.metrics.days_of_emails_remaining;

    if (state === 'Auto-Approve') return 'Auto-Approve';

    if (days === 'Infinity') return 'Emails remain';
    if (days === 0) return 'No approved emails';
    return `${days.toFixed(1)} days remain`;
  };

  const getProspectHealthText = (health) => {
    if (!health?.prospects_health?.state) return '';

    return health.prospects_health.state === 'FUNNEL_RUNNING' ? 'ON' : 'OFF';
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const getCapacityStatus = () => {
    if (!overallPacing) return null;

    const monthlyPacing = overallPacing.pacing; // Assuming 30 days per month
    const monthlyCredits = overallPacing.monthly_credits;
    const usagePercentage = (monthlyPacing / monthlyCredits) * 100;

    if (usagePercentage < 65) return 'red';
    if (usagePercentage < 85) return 'amber';
    return null;
  };

  if (loading) {
    return (
      <div className='campaigns-list loading'>
        <h1>Campaigns</h1>
        <div className='dash-loading-state'></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='campaigns-list'>
        <h1>Campaigns</h1>
        <div className='dashboard-empty'>
          <ErrorIcon className='dashboard-error-icon' />
          <div>
            <h2 className='dashboard-empty-title'>
              We're having trouble getting your data
            </h2>
            <p className='dashboard-empty-text'>Try reloading the page</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='campaigns-list'>
      <h1>Campaigns</h1>
      {overallPacing && getCapacityStatus() && (
        <div className={`capacity-warning ${getCapacityStatus()}`}>
          <WarningIcon />
          <div className='warning-content'>
            <h3>You have unused capacity</h3>
            <p>
              You're only contacting {Math.round(overallPacing.pacing)}{' '}
              prospects out of a maximum of {overallPacing.monthly_credits} each
              month.
            </p>
            <div className='warning-actions'>
              <a href='/approvals' className='action-link'>
                Complete your approvals ({overallPacing.pending_approvals || 0}{' '}
                remaining)
              </a>
              <a href='/campaigns/new' className='action-link'>
                Create a new campaign
              </a>
            </div>
          </div>
        </div>
      )}
      {campaigns.active.length > 0 && (
        <div className='campaigns-section'>
          <h2
            onClick={() => toggleSection('active')}
            className='section-header'
          >
            Active <span>({campaigns.active.length})</span>
            {campaigns.active.length > 0 &&
              (expandedSections.active ? (
                <FontAwesomeIcon icon={faCaretDown} />
              ) : (
                <FontAwesomeIcon icon={faCaretRight} />
              ))}
          </h2>
          {expandedSections.active &&
            campaigns.active.map((campaign) => (
              <div
                key={campaign.id}
                className={`campaign-card ${getHealthClass(campaign.id)}`}
              >
                <h3>{campaign.name}</h3>
                <div className='lower-area'>
                  <div className='stats'>
                    <div className='stat'>
                      <label>PACING</label>
                      <p className='value'>
                        {pacingLoading[campaign.id] ? (
                          <Spinner size='sm' />
                        ) : (
                          monthlyPacing[campaign.id]?.toLocaleString() || '-'
                        )}
                      </p>
                    </div>
                    <div className='stat'>
                      <label>REPLY RATE</label>
                      <p className='value'>
                        {campaign.dash_data?.messages_sent > 0
                          ? `${(
                              (campaign.dash_data?.replies /
                                campaign.dash_data?.messages_sent) *
                              100
                            ).toFixed(2)}%`
                          : '-'}
                      </p>
                    </div>
                  </div>
                  <div className='health-status'>
                    {healthLoading[campaign.id] ? (
                      <Spinner size='sm' />
                    ) : (
                      <>
                        <div
                          className={`health-item ${campaignHealth[campaign.id]?.approved_email_health?.health?.toLowerCase() || ''}`}
                        >
                          <label>EMAIL HEALTH</label>
                          <p className='value'>
                            {getEmailHealthText(campaignHealth[campaign.id])}
                          </p>
                        </div>
                        <div
                          ref={(el) =>
                            (tooltipTargets.current[campaign.id] = el)
                          }
                          className={`prospecting-health ${campaignHealth[campaign.id]?.prospects_health?.health?.toLowerCase() || ''}`}
                          onMouseEnter={() =>
                            setShowTooltip({
                              ...showTooltip,
                              [campaign.id]: true,
                            })
                          }
                          onMouseLeave={() =>
                            setShowTooltip({
                              ...showTooltip,
                              [campaign.id]: false,
                            })
                          }
                          onClick={() => {
                            if (
                              campaignHealth[campaign.id]?.prospects_health
                                ?.state !== 'FUNNEL_RUNNING' &&
                              campaignHealth[campaign.id]?.funnels?.length > 0
                            ) {
                              navigate(
                                `/funnel/${campaignHealth[campaign.id].funnels[0]}`,
                              );
                            }
                          }}
                        >
                          <AutoProspectingIcon />
                          {getProspectHealthText(campaignHealth[campaign.id])}
                          <Overlay
                            target={tooltipTargets.current[campaign.id]}
                            show={showTooltip[campaign.id]}
                            placement='top'
                          >
                            {(props) => (
                              <Tooltip {...props}>
                                Auto-prospecting is{' '}
                                {getProspectHealthText(
                                  campaignHealth[campaign.id],
                                )}
                              </Tooltip>
                            )}
                          </Overlay>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}

      {campaigns.draft.length > 0 && (
        <div className='campaigns-section'>
          <h2 onClick={() => toggleSection('draft')} className='section-header'>
            Draft <span>({campaigns.draft.length})</span>
            {campaigns.draft.length > 0 &&
              (expandedSections.draft ? (
                <FontAwesomeIcon icon={faCaretDown} />
              ) : (
                <FontAwesomeIcon icon={faCaretRight} />
              ))}
          </h2>
          {expandedSections.draft &&
            campaigns.draft.map((campaign) => (
              <div key={campaign.id} className='campaign-card draft'>
                <div className='draft-icon'>
                  <HornIcon />
                </div>
                <div className='draft-content'>
                  <p>
                    <b>{campaign.name}</b> needs to be completed
                  </p>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default CampaignsList;
