import React, { useState, useEffect } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as CampaignsIcon } from '../../icons/campaigns.svg';
import cc from 'currency-codes';

import MatchUseCaseDescription from '../misc/MatchUseCaseDescription';
import DreamModalInner from '../misc/DreamModalInner';
import DreamSideModal from '../misc/DreamSideModal';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';

import api from '../../api';
import './campaigns.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import CharacterTracker from '../misc/CharacterTracker';

const maxChars = 500;

const CreateCampaignModal = ({
  show,
  handleClose,
  initialTargetLists = [],
}) => {
  const [name, setName] = useState('');
  const [purpose, setPurpose] = useState('');
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);
  const [currency, setCurrency] = useState('');
  const [dealValue, setDealValue] = useState('');
  const [loadingSettings, setLoadingSettings] = useState(true);
  const [settings, setSettings] = useState(null);

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [showMatchModal, setShowMatchModal] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/settings', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSettings(response.data);
        setCurrency(response.data.default_currency || 'USD');
      } catch (error) {
        console.error('Error fetching settings:', error);
        setCurrency('USD'); // Fallback to USD
      } finally {
        setLoadingSettings(false);
      }
    };

    if (show) {
      fetchSettings();
    }
  }, [getAccessTokenSilently, show]);

  const handleCreate = (event) => {
    event.preventDefault();
    setShowMatchModal(true); // Show MatchUseCaseDescription modal
  };

  const handleAdvance = async ({ products }) => {
    setCreating(true);
    setError(null);

    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/oagents',
        {
          name,
          use_case_type: 'general',
          use_case_description: purpose,
          match_logic: { products },
          initial_target_lists: initialTargetLists,
          currency,
          deal_value: dealValue ? parseFloat(dealValue) : null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const newOagentId = response.data.id;
      navigate(`/campaign/${newOagentId}`);
    } catch (error) {
      console.error('Error creating campaign:', error);
      setError('There was a problem creating the campaign. Please try again.');
      setCreating(false);
    } finally {
      setShowMatchModal(false);
    }
  };

  const currencies = cc.codes().map((code) => ({
    code,
    symbol: cc.code(code)?.symbol || code,
    name: cc.code(code)?.currency || code,
  }));

  console.log(cc.codes());

  if (creating) {
    return (
      <DreamSideModal show={show}>
        <DreamModalInner>
          <DreamSideModalLoader>Creating Campaign</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <>
      <DreamSideModal show={show && !showMatchModal} handleClose={handleClose}>
        <DreamModalInner>
          <DreamModalInner.Header>
            <CampaignsIcon /> Create new campaign
          </DreamModalInner.Header>
          <DreamModalInner.Body>
            <Form className='dream-side-modal-form campaigns-form'>
              <Form.Group controlId='oagentName'>
                <Form.Label>Campaign name</Form.Label>
                <Form.Text>For example 'SME Prospects'</Form.Text>
                <Form.Control
                  type='text'
                  value={name}
                  size='lg'
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId='oagentPurpose'>
                <Form.Label>What is the purpose of this campaign?</Form.Label>
                <Form.Text>
                  When the prospect replies, agents will use this purpose to
                  influence their response.
                </Form.Text>
                <Form.Control
                  as='textarea'
                  rows={4}
                  placeholder='e.g. This outbound agent will be used to contact potential SME prospects to try and book a call and demo our new MagiTouch product.'
                  value={purpose}
                  onChange={(e) => setPurpose(e.target.value)}
                  required
                />
                <CharacterTracker
                  recommendations={[
                    { chars: 30, message: 'Keep going...', iconType: null },
                    { chars: 60, message: 'Good', iconType: 'redThumb' },
                    { chars: 150, message: 'Great!', iconType: 'greenThumb' },
                    { chars: 2000, message: 'Perfect!', iconType: 'heart' },
                  ]}
                  value={purpose}
                  description='We recommend writing at least 150 characters'
                  maxChars={maxChars}
                />
              </Form.Group>

              <Form.Group controlId='oagentCurrency'>
                <Form.Label>Average customer value (Optional)</Form.Label>
                <Form.Text>
                  Set the average value of a deal for this campaign. You can
                  edit this later.
                </Form.Text>
                <div className='d-flex gap-2'>
                  <Form.Select
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    style={{ width: '120px' }}
                    disabled={loadingSettings}
                    size='lg'
                  >
                    {currencies.map(({ code }) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Control
                    type='number'
                    min='0'
                    step='0.01'
                    value={dealValue}
                    onChange={(e) => setDealValue(e.target.value)}
                    placeholder='Enter value'
                    size='lg'
                  />
                </div>
              </Form.Group>

              {error && <Alert variant='danger'>{error}</Alert>}
            </Form>
          </DreamModalInner.Body>
          <DreamModalInner.Footer>
            <div className='campaigns'>
              <Button
                onClick={handleCreate}
                variant='primary'
                type='submit'
                disabled={creating}
              >
                Create Campaign <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
            <Button
              variant='outline-primary'
              onClick={handleClose}
              disabled={creating}
            >
              Close
            </Button>
          </DreamModalInner.Footer>
        </DreamModalInner>
      </DreamSideModal>
      <MatchUseCaseDescription
        show={showMatchModal}
        handleClose={() => setShowMatchModal(false)}
        useCaseDescription={purpose}
        onAdvance={handleAdvance}
      />
    </>
  );
};

export default CreateCampaignModal;
