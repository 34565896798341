import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import ConfirmModal from '../confirm-modals/ConfirmModal';

const ActivityConfirmModal = ({
  show,
  onHide,
  activityType, // 'connection' or 'call'
  prospects,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [showTakeOffline, setShowTakeOffline] = useState(false);

  const markActivityDone = async () => {
    const token = await getAccessTokenSilently();
    await Promise.all(
      prospects.map((prospect) =>
        api.post(
          `/prospects/${prospect.id}/activity-done`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      ),
    );
  };

  const handleYes = async () => {
    try {
      await markActivityDone();

      if (activityType === 'connection') {
        onHide(true);
      } else if (activityType === 'call') {
        setShowTakeOffline(true);
      }
    } catch (error) {
      console.error('Error recording activity:', error);
    }
  };

  const handleTakeOffline = async () => {
    try {
      const token = await getAccessTokenSilently();
      await Promise.all(
        prospects.map((prospect) =>
          api.post(
            `/prospects/${prospect.id}/take-offline`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          ),
        ),
      );
      onHide(true);
    } catch (error) {
      console.error('Error taking conversation offline:', error);
    }
  };

  const title =
    activityType === 'connection'
      ? 'Did you manage to send a request?'
      : 'Did you get through?';

  const yesText =
    activityType === 'connection'
      ? "Yes, I've sent a request"
      : 'Yes, I got through';

  if (showTakeOffline) {
    return (
      <ConfirmModal
        show={show}
        onHide={onHide}
        title='Take conversation offline?'
        bodyText='Would you like to mark this conversation as taken offline? This will remove them from future campaigns.'
        confirmLabel='Yes, take offline'
        cancelLabel='No, keep in campaigns'
        handleConfirm={handleTakeOffline}
        handleClose={onHide}
        littleSweetieVersion
        confirmFirst
      />
    );
  }

  return (
    <ConfirmModal
      show={show}
      onHide={onHide}
      title={title}
      bodyText="Letting us know that you've contacted the prospect will help us better track activity."
      confirmLabel={yesText}
      cancelLabel="No, I didn't"
      handleConfirm={handleYes}
      handleClose={onHide}
      littleSweetieVersion
      confirmFirst
    />
  );
};

export default ActivityConfirmModal;
