import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../api';
import './dashboard/old-dashboard.scss';
import TaskList from './tasks/TaskList';
import NewLeads from './dashboard/NewLeads';
import Feed from './feed/Feed';
import DashChart from './dashboard/DashChart';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [conversationStats, setConversationStats] = useState(null);
  const [prospectCount, setProspectCount] = useState(null);
  const [emailCount, setEmailCount] = useState(null);
  const [linkClickCount, setLinkClickCount] = useState(null);
  const [openCount, setOpenCount] = useState(null);
  const [websiteVisitCount, setWebsiteVisitCount] = useState(null);
  const [trackingChecks, setTrackingChecks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedInterval, setSelectedInterval] = useState(7);
  const [selectedDatasets, setSelectedDatasets] = useState(['prospects']);
  const hasShownLoadingRef = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!hasShownLoadingRef.current) {
        setLoading(true);
      }

      const token = await getAccessTokenSilently();
      try {
        const fetchConversations = api.get(
          `/conversations?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchProspectCount = api.get(
          `/dashboard/prospect-count?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchEmailCount = api.get(
          `/dashboard/email-count?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchLinkClickCount = api.get(
          `/dashboard/link-click-count?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchWebsiteVisitCount = api.get(
          `/dashboard/website-visit-count?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchOpenCount = api.get(
          `/dashboard/open-count?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const checkForDomains = async () => {
          try {
            const response = await api.get('/tracking-check/check', {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setTrackingChecks(response.data);
          } catch (error) {
            if (error.response && error.response.status === 404) {
              setTrackingChecks({ domain: null, emailLinks: 0 });
            } else {
              console.error('Error checking domain:', error);
              throw error;
            }
          }
        };

        const [
          responseConversations,
          responseProspectCount,
          responseEmailCount,
          responseLinkClickCount,
          responseWebsiteVisitCount,
          responseOpenCount,
        ] = await Promise.all([
          fetchConversations,
          fetchProspectCount,
          fetchEmailCount,
          fetchLinkClickCount,
          fetchWebsiteVisitCount,
          fetchOpenCount,
        ]).catch(() => {});

        await checkForDomains();

        setConversationStats(responseConversations.data);
        setProspectCount(responseProspectCount.data);
        setEmailCount(responseEmailCount.data);
        setLinkClickCount(responseLinkClickCount.data);
        setWebsiteVisitCount(responseWebsiteVisitCount.data);
        setOpenCount(responseOpenCount.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
        hasShownLoadingRef.current = true;
      }
    };

    fetchData();

    const fetchInterval = setInterval(fetchData, 1000 * 60 * 5); // Refresh stats five minutes

    return () => clearInterval(fetchInterval); // Clear interval on component unmount
  }, [getAccessTokenSilently, selectedInterval]);

  const handleIntervalChange = (newInterval) => {
    setSelectedInterval(newInterval);
  };

  const toggleDataset = (dataset) => {
    setSelectedDatasets((prevDatasets) =>
      prevDatasets.includes(dataset)
        ? prevDatasets.filter((d) => d !== dataset)
        : [...prevDatasets, dataset],
    );
  };

  const getTotalProspects = () => {
    if (!prospectCount) return 0;
    return Object.keys(prospectCount.breakdown).reduce(
      (prev, curr) => prev + prospectCount.breakdown[curr],
      0,
    );
  };

  const getNewProspects = () => {
    if (!prospectCount) return 0;
    return prospectCount.new_prospects;
  };

  const getTotalEmailsSent = () => {
    if (!emailCount) return 0;
    return Object.keys(emailCount.breakdown).reduce(
      (prev, curr) => prev + emailCount.breakdown[curr],
      0,
    );
  };

  const getLinkClicks = () => {
    if (!linkClickCount) return 0;
    return linkClickCount.new_clicks;
  };

  const getWebsiteVisits = () => {
    if (!websiteVisitCount) return 0;
    return websiteVisitCount.new_visits;
  };

  const getNewEmailsSent = () => {
    if (!emailCount) return 0;
    return emailCount.new_emails;
  };

  const getRepliesCount = () => {
    if (!conversationStats) return 0;
    return Object.keys(conversationStats.breakdown).reduce((prev, curr) => {
      const obj = conversationStats.breakdown[curr];
      return (
        prev + obj?.replied + obj?.booked + obj?.success + obj?.fail_with_reply
      );
    }, 0);
  };

  const getOpenCount = () => {
    if (!openCount) return 0;
    return openCount.new_opens;
  };

  const getTotalConversations = () => {
    return Object.keys(conversationStats.breakdown).reduce((prev, curr) => {
      const obj = conversationStats.breakdown[curr];
      return (
        prev +
        obj?.replied +
        obj?.booked +
        obj?.fail_with_reply +
        obj?.fail_without_reply +
        obj?.success +
        obj?.contacted +
        obj?.viewed
      );
    }, 0);
  };

  const getRepliesPercentage = () => {
    if (!conversationStats) return 0;
    const totalConversations = getTotalConversations();
    const totalReplies = getRepliesCount();
    return totalConversations > 0
      ? ((totalReplies / totalConversations) * 100).toFixed(2)
      : 0;
  };

  const getCallsBooked = () => {
    if (!conversationStats) return 0;
    return Object.keys(conversationStats.breakdown).reduce((prev, curr) => {
      const obj = conversationStats.breakdown[curr];
      return prev + obj?.booked + obj?.success;
    }, 0);
  };

  const getCallsBookedPercentage = () => {
    if (!conversationStats) return 0;
    const totalConversations = getTotalConversations();
    const totalBooked =
      getCallsBooked() + (conversationStats.success?.all_time || 0);
    return totalConversations > 0
      ? ((totalBooked / totalConversations) * 100).toFixed(1)
      : 0;
  };

  const polite = {
    prospects: 'New prospects',
    emails: 'Total emails sent',
    replies: 'Replies',
    meetings: 'Meetings booked',
    visits: 'Website visits',
    opens: 'Email opens',
    clicks: 'Link clicks',
  };

  const clickTrackingDisabled =
    trackingChecks && trackingChecks.emailLinks === 0;
  const websiteVisitDisabled = trackingChecks && !trackingChecks.domain;

  return (
    <div className='page-wrapper old-dashboard'>
      <div className='page-inner-wrapper main-dashboard'>
        <div className='top-area-wrapper'>
          <div className='dream-top-boxes bolder'>
            <h4>Goal Performance</h4>
            <div className='box-holder'>
              <div
                className={`top-box prospects ${selectedDatasets.includes('prospects') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
                onClick={() => toggleDataset('prospects')}
              >
                <h5>New Prospects</h5>
                <p>
                  {getTotalProspects()}
                  {selectedInterval === 7 && <span>+ {getNewProspects()}</span>}
                </p>
              </div>
              <div
                className={`top-box emails ${selectedDatasets.includes('emails') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
                onClick={() => toggleDataset('emails')}
              >
                <h5>Total Emails Sent</h5>
                <p>
                  {getTotalEmailsSent()}
                  {selectedInterval === 7 && (
                    <span>+ {getNewEmailsSent()}</span>
                  )}
                </p>
              </div>
            </div>
            <div className='box-separator'></div>
            <div className='box-holder'>
              <div
                className={`top-box opens ${selectedDatasets.includes('opens') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
                onClick={() => toggleDataset('opens')}
              >
                <h5>Email Opens</h5>
                <p>{getOpenCount()}</p>
              </div>

              <div
                className={`top-box replies ${selectedDatasets.includes('replies') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
                onClick={() => toggleDataset('replies')}
              >
                <h5>Responses</h5>
                <p>
                  {getRepliesCount()}
                  <span>{getRepliesPercentage()}%</span>
                </p>
              </div>
            </div>
            <div className='box-holder'>
              {/* <div
                className={`top-box meetings ${selectedDatasets.includes('meetings') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
                onClick={() => toggleDataset('meetings')}
              >
                <h5>Meetings booked</h5>
                <p>
                  {getCallsBooked()}
                  <span>{getCallsBookedPercentage()}%</span>
                </p>
              </div> */}
              <div
                className={`top-box clicks ${selectedDatasets.includes('clicks') ? 'selected' : ''} ${loading ? 'loading' : ''} ${clickTrackingDisabled ? 'disabled' : ''}`}
                onClick={
                  clickTrackingDisabled
                    ? () => {}
                    : () => toggleDataset('clicks')
                }
              >
                <h5>Link Clicks</h5>
                {clickTrackingDisabled ? (
                  <div className='disabled-explainer'>No links sent</div>
                ) : (
                  <p>{getLinkClicks()}</p>
                )}
              </div>
              <div
                className={`top-box visits ${selectedDatasets.includes('visits') ? 'selected' : ''} ${loading ? 'loading' : ''} ${websiteVisitDisabled ? 'disabled' : ''}`}
                onClick={
                  websiteVisitDisabled
                    ? () => {}
                    : () => toggleDataset('visits')
                }
              >
                <h5>Website Visits</h5>
                {websiteVisitDisabled ? (
                  <div className='disabled-explainer'>
                    <Link to='/settings/web-tracking'>Go to setup</Link>
                  </div>
                ) : (
                  <p>{getWebsiteVisits()}</p>
                )}
              </div>
            </div>
          </div>

          <div className={`chart-management ${loading && 'loading'}`}>
            {!loading && (
              <>
                <div className='selected-and-toggle'>
                  <div className='interval-toggle'>
                    <div
                      className={selectedInterval === 7 ? 'active' : ''}
                      onClick={() => handleIntervalChange(7)}
                    >
                      7 Days
                    </div>
                    <div
                      className={selectedInterval === 30 ? 'active' : ''}
                      onClick={() => handleIntervalChange(30)}
                    >
                      30 Days
                    </div>
                    <div
                      className={selectedInterval === 'all' ? 'active' : ''}
                      onClick={() => handleIntervalChange('all')}
                    >
                      All Time
                    </div>
                  </div>
                  <div className='selected-datasets'>
                    {selectedDatasets.map((set) => (
                      <div className={`dataset-choice ${set}`} key={set}>
                        <div className='point'></div>
                        <span>{polite[set]}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <DashChart
                  conversationStats={conversationStats}
                  prospectCount={prospectCount}
                  emailCount={emailCount}
                  websiteVisitCount={websiteVisitCount}
                  linkClickCount={linkClickCount}
                  openCount={openCount}
                  selectedDatasets={selectedDatasets}
                  showTimesAs={
                    parseInt(selectedInterval, 10) < 30 ? 'DAYS' : 'WEEKS'
                  }
                />
              </>
            )}
          </div>
        </div>

        <div className='lower-area'>
          <div className='lower-side-wrapper'>
            <div className='tasks-wrapper'>
              <TaskList />
            </div>
            <div className='new-leads-wrapper'>
              <NewLeads />
            </div>
          </div>
          <div className='feed-outer-wrapper'>
            <Feed />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
