import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import cc from 'currency-codes';
import CampaignWizardStepHeader from './CampaignWizardStepHeader';
import api from '../../../api';
import './settings.css';

const Settings = ({ oagentId, advance, retreat, nextText }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [supervised, setSupervised] = useState(true);
  const [responsesSupervised, setResponsesSupervised] = useState(true);
  const [crmMatchProspectBehaviour, setCrmMatchProspectBehaviour] =
    useState('DO_NOT_CONTACT');
  const [openTracking, setOpenTracking] = useState(false);
  const [stopEmailsOnceReplied, setStopEmailsOnceReplied] = useState(false);
  const [clickTracking, setClickTracking] = useState(false);
  const [currency, setCurrency] = useState('USD');
  const [dealValue, setDealValue] = useState('');
  const [loadingSettings, setLoadingSettings] = useState(true);

  const loadSettings = async () => {
    try {
      const token = await getAccessTokenSilently();
      const { data } = await api.get(`/oagents/${oagentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSupervised(data.supervised ?? true);
      setResponsesSupervised(data.responses_supervised ?? true);
      setCrmMatchProspectBehaviour(
        data.metadata.crm_match_prospect_behaviour ?? 'DO_NOT_CONTACT',
      );
      setOpenTracking(data.email_open_tracking ?? false);
      setStopEmailsOnceReplied(data.stop_emails_once_replied ?? false);
      setClickTracking(data.link_tracking ?? false);
      setCurrency(data.currency || 'USD');
      setDealValue(data.deal_value?.toString() || '');
      setLoadingSettings(false);
    } catch (err) {
      console.error('Error loading settings:', err);
      setLoadingSettings(false);
    }
  };

  const updateDealValue = async (newCurrency, newValue) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/${oagentId}/adjust-value`,
        {
          currency: newCurrency,
          deal_value: newValue ? parseFloat(newValue) : null,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (err) {
      console.error('Error updating deal value:', err);
    }
  };

  const handleCurrencyChange = async (e) => {
    const newCurrency = e.target.value;
    setCurrency(newCurrency);
    await updateDealValue(newCurrency, dealValue);
  };

  const handleDealValueChange = async (e) => {
    const newValue = e.target.value;
    setDealValue(newValue);
    await updateDealValue(currency, newValue);
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const currencies = cc.codes();

  const handleSupervisedChange = async (value) => {
    setSupervised(value === 'true');
    await updateSupervisedSettings(value === 'true', responsesSupervised);
  };

  const handleResponsesSupervisedChange = async (value) => {
    setResponsesSupervised(value === 'true');
    await updateSupervisedSettings(supervised, value === 'true');
  };

  const handleCrmMatchProspectBehaviourChange = async (value) => {
    setCrmMatchProspectBehaviour(value);
    await updateCrmMatchProspectBehaviour(value);
  };

  const handleOpenTrackingChange = async (value) => {
    setOpenTracking(value === 'true');
    await updateOpenTracking(value === 'true');
  };

  const handleClickTrackingChange = async (value) => {
    setClickTracking(value === 'true');
    await updateClickTracking(value === 'true');
  };

  const handleStopEmailsOnceRepliedChange = async (value) => {
    setStopEmailsOnceReplied(value === 'true');
    await updateStopEmailsOnceReplied(value === 'true');
  };

  const updateSupervisedSettings = async (supervised, responsesSupervised) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/${oagentId}/toggle-supervised`,
        { supervised, responses_supervised: responsesSupervised },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (err) {
      console.error('Error updating supervised settings:', err);
    }
  };

  const updateCrmMatchProspectBehaviour = async (crmMatchProspectBehaviour) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/${oagentId}/email-strategy`,
        { crm_match_prospect_behaviour: crmMatchProspectBehaviour },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (err) {
      console.error('Error updating CRM match prospect behaviour:', err);
    }
  };

  const updateOpenTracking = async (openTracking) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/${oagentId}/open-tracking`,
        { open_tracking: openTracking },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (err) {
      console.error('Error updating open tracking:', err);
    }
  };

  const updateStopEmailsOnceReplied = async (stopEmailsOnceReplied) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/${oagentId}/stop-emails-once-replied`,
        { stop_emails_once_replied: stopEmailsOnceReplied },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (err) {
      console.error('Error updating stop emails once replied:', err);
    }
  };

  const updateClickTracking = async (clickTracking) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/${oagentId}/link-tracking`,
        { link_tracking: clickTracking },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (err) {
      console.error('Error updating click tracking:', err);
    }
  };

  return (
    <div className='campaign-settings dream-side-modal-form'>
      <Form>
        <Form.Group controlId='dealValue' className='mb-4'>
          <Form.Label>Average customer value</Form.Label>
          <Form.Text>
            Set the average value of a deal for this campaign.
          </Form.Text>
          <div className='d-flex gap-2'>
            <Form.Select
              value={currency}
              onChange={handleCurrencyChange}
              style={{ width: '120px' }}
              disabled={loadingSettings}
              size='lg'
            >
              {currencies.map((code) => (
                <option key={code} value={code}>
                  {code}
                </option>
              ))}
            </Form.Select>

            <Form.Control
              type='number'
              min='0'
              step='1'
              value={dealValue}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '' || /^\d+$/.test(value)) {
                  handleDealValueChange(e);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === '.' || e.key === ',') {
                  e.preventDefault();
                }
              }}
              placeholder='Enter value'
              size='lg'
            />
          </div>
        </Form.Group>

        <div className='setting-group'>
          <Form.Label>Outbound approvals</Form.Label>
          <Form.Text>Do you want agents to auto-approve messages?</Form.Text>
          <div className='options-wrapper'>
            <Form.Check
              type='radio'
              label='No, I will approve all outgoing mail before sending.'
              value='true'
              checked={supervised === true}
              onChange={(e) => handleSupervisedChange(e.target.value)}
            />
            <Form.Check
              type='radio'
              label='Yes, agents should approve outbound emails themselves.'
              value='false'
              checked={supervised === false}
              onChange={(e) => handleSupervisedChange(e.target.value)}
            />
          </div>
        </div>

        <div className='setting-group'>
          <Form.Label>Reply approvals</Form.Label>
          <Form.Text>Do you want agents to auto-approve replies?</Form.Text>
          <div className='options-wrapper'>
            <Form.Check
              type='radio'
              label='No, I want to approve replies before they are sent.'
              value='true'
              checked={responsesSupervised === true}
              onChange={(e) => handleResponsesSupervisedChange(e.target.value)}
            />
            <Form.Check
              type='radio'
              label='Yes, agents should approve replies themselves.'
              value='false'
              checked={responsesSupervised === false}
              onChange={(e) => handleResponsesSupervisedChange(e.target.value)}
            />
          </div>
        </div>

        <div className='setting-group'>
          <Form.Label>CRM has matching prospect</Form.Label>
          <Form.Text>
            Should your agents contact companies that have previously been
            contacted?
          </Form.Text>
          <div className='options-wrapper'>
            <Form.Check
              type='radio'
              label="Yes, they can contact companies we've previously contacted."
              value='CONTACT'
              checked={crmMatchProspectBehaviour === 'CONTACT'}
              onChange={(e) =>
                handleCrmMatchProspectBehaviourChange(e.target.value)
              }
            />
            <Form.Check
              type='radio'
              label='No, do not contact companies that have previously been contacted.'
              value='DO_NOT_CONTACT'
              checked={crmMatchProspectBehaviour === 'DO_NOT_CONTACT'}
              onChange={(e) =>
                handleCrmMatchProspectBehaviourChange(e.target.value)
              }
            />
          </div>
        </div>

        <div className='setting-group'>
          <Form.Label>Open Tracking</Form.Label>
          <Form.Text>
            Do you want to track opens for this campaign? (not recommended for
            new accounts)
          </Form.Text>
          <div className='options-wrapper'>
            <Form.Check
              type='radio'
              label={
                <>
                  Yes, I want to track opens for this campaign{' '}
                  <b>(not recommended for new accounts)</b>.
                </>
              }
              value='true'
              checked={openTracking === true}
              onChange={(e) => handleOpenTrackingChange(e.target.value)}
            />
            <Form.Check
              type='radio'
              label='No, I do not want to track opens.'
              value='false'
              checked={openTracking === false}
              onChange={(e) => handleOpenTrackingChange(e.target.value)}
            />
          </div>
        </div>

        <div className='setting-group'>
          <Form.Label>Link Click Tracking</Form.Label>
          <Form.Text>
            Do you want to track clicks on links for this campaign?
          </Form.Text>
          <div className='options-wrapper'>
            <Form.Check
              type='radio'
              label={<>Yes, I want to track link clicks for this campaign </>}
              value='true'
              checked={clickTracking === true}
              onChange={(e) => handleClickTrackingChange(e.target.value)}
            />
            <Form.Check
              type='radio'
              label='No, I do not want to track clicks.'
              value='false'
              checked={clickTracking === false}
              onChange={(e) => handleClickTrackingChange(e.target.value)}
            />
          </div>
        </div>

        <div className='setting-group'>
          <Form.Label>Stop All Messages on Reply</Form.Label>
          <Form.Text>
            Do you want to stop all messages to a company once a prospect from
            that company replies?
          </Form.Text>
          <div className='options-wrapper'>
            <Form.Check
              type='radio'
              label={<>Yes, stop all messages </>}
              value='true'
              checked={stopEmailsOnceReplied === true}
              onChange={(e) =>
                handleStopEmailsOnceRepliedChange(e.target.value)
              }
            />
            <Form.Check
              type='radio'
              label='No, messages should continue.'
              value='false'
              checked={stopEmailsOnceReplied === false}
              onChange={(e) =>
                handleStopEmailsOnceRepliedChange(e.target.value)
              }
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Settings;
