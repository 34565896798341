// src/components/NewLeadsModal.js
import React from 'react';
import DreamModalInner from '../misc/DreamModalInner';
import DreamSideModal from '../misc/DreamSideModal';
import FakePic from '../feed/items/FakePic';
import { formatDistanceToNow, parseISO } from 'date-fns';
import './dashboard.scss';
import LeadStatus from './LeadStatus';
import { useNavigate } from 'react-router-dom';

const NewLeadsModal = ({ show, onHide, newLeads }) => {
  const navigate = useNavigate();

  return (
    <DreamSideModal show={show} handleClose={onHide}>
      <DreamModalInner>
        <DreamModalInner.Header>All Hot Leads</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='all-leads new-leads'>
            {newLeads.length > 0 ? (
              newLeads.map((prospect) => (
                <div
                  className='new-lead lead-item'
                  key={prospect.id}
                  onClick={() =>
                    navigate(`/prospect/${prospect.id}?origin=dashboard`)
                  }
                >
                  <div className='pic-area'>
                    <FakePic url={prospect.logo_url} />
                  </div>
                  <div className='left'>
                    <p>{prospect.name}</p>
                    <small>{prospect.org_name}</small>
                  </div>
                  <div className='status-outer-wrapper'>
                    <LeadStatus prospect={prospect} />
                  </div>
                  <small>
                    {formatDistanceToNow(parseISO(prospect.time), {
                      addSuffix: true,
                    })}
                  </small>
                </div>
              ))
            ) : (
              <div className='empty-state'>
                <h6>No hot leads found</h6>
              </div>
            )}
          </div>
        </DreamModalInner.Body>
      </DreamModalInner>
    </DreamSideModal>
  );
};

export default NewLeadsModal;
