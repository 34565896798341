import React from 'react';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { format, parseISO, startOfWeek } from 'date-fns';

const polite = {
  prospects: 'New prospects',
  emails: 'Total emails sent',
  replies: 'Replies',
  meetings: 'Meetings booked',
  opens: 'Email opens',
  clicks: 'Link clicks',
  visits: 'Website visits',
};

const gradients = [
  { id: 'colorProspects', color: '#64B6AC' },
  { id: 'colorEmails', color: '#F67E7D' },
  { id: 'colorOpens', color: '#07A0C3' },
  { id: 'colorVisits', color: '#72195A' },
  { id: 'colorClicks', color: '#F4D06F' },
  { id: 'colorReplies', color: '#8338EC' },
  { id: 'colorMeetings', color: '#8338EC' },
];

function CustomTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    const uniqueDataKeys = new Set();

    return (
      <div className='custom-tooltip'>
        {payload.map((entry, index) => {
          if (uniqueDataKeys.has(entry.dataKey)) {
            return null;
          }
          uniqueDataKeys.add(entry.dataKey);
          return (
            <div key={index} className='value-wrapper'>
              <div
                className='dot'
                style={{ backgroundColor: entry.stroke }}
              ></div>
              <p className='label'>
                {polite[entry.name]}: {entry.value}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}

const DashChart = ({
  conversationStats,
  prospectCount,
  emailCount,
  linkClickCount,
  openCount,
  websiteVisitCount,
  selectedDatasets,
  showTimesAs,
}) => {
  // Transform data for the chart
  const transformData = () => {
    if (
      !conversationStats ||
      !prospectCount ||
      !emailCount ||
      !openCount ||
      !linkClickCount ||
      !websiteVisitCount
    )
      return [];

    const dates = Object.keys(conversationStats.breakdown || {});
    return dates.map((date) => ({
      name: date,
      prospects: prospectCount?.breakdown[date] || 0,
      emails: emailCount?.breakdown[date] || 0,
      replies:
        (conversationStats?.breakdown[date]?.replied || 0) +
        (conversationStats?.breakdown[date]?.booked || 0) +
        (conversationStats?.breakdown[date]?.success || 0) +
        (conversationStats?.breakdown[date]?.fail_with_reply || 0),
      meetings: conversationStats?.breakdown[date]?.booked || 0,
      clicks: linkClickCount?.breakdown[date] || 0,
      opens: openCount?.breakdown[date] || 0,
      visits: websiteVisitCount?.breakdown[date] || 0,
    }));
  };

  const data = transformData();

  // Format the date for XAxis ticks conditionally based on showTimesAs prop
  const formatXAxis = (tickItem) => {
    if (showTimesAs === 'WEEKS') {
      // Format for weeks e.g., w/c 18 Aug
      const startOfWeekDate = startOfWeek(parseISO(tickItem));
      return `w/c ${format(startOfWeekDate, 'dd MMM')}`;
    }
    // Default format is for DAYS
    return format(parseISO(tickItem), 'EEE, dd MMM');
  };

  const datasets = [
    {
      dataKey: 'prospects',
      stroke: gradients[0].color,
      fill: gradients[0].id,
    },
    {
      dataKey: 'emails',
      stroke: gradients[1].color,
      fill: gradients[1].id,
    },
    {
      dataKey: 'opens',
      stroke: gradients[2].color,
      fill: gradients[2].id,
    },
    {
      dataKey: 'visits',
      stroke: gradients[3].color,
      fill: gradients[3].id,
    },
    {
      dataKey: 'clicks',
      stroke: gradients[4].color,
      fill: gradients[4].id,
    },
    {
      dataKey: 'replies',
      stroke: gradients[5].color,
      fill: gradients[5].id,
    },
    {
      dataKey: 'meetings',
      stroke: gradients[6].color,
      fill: gradients[6].id,
    },
  ];

  return (
    <div>
      <ResponsiveContainer
        width='100%'
        height={selectedDatasets.length >= 5 ? 248 : 308}
      >
        <ComposedChart data={data}>
          <defs>
            {gradients.map((gradient) => (
              <linearGradient
                key={gradient.id}
                id={gradient.id}
                x1='0'
                y1='0'
                x2='0'
                y2='1'
              >
                <stop
                  offset='0%'
                  stopColor={gradient.color}
                  stopOpacity={0.8}
                />
                <stop offset='90%' stopColor={gradient.color} stopOpacity={0} />
                <stop offset='100%' stopColor='#ffffff' stopOpacity={0} />
              </linearGradient>
            ))}
          </defs>
          <CartesianGrid vertical={false} strokeDasharray='3 3' />
          <XAxis
            dataKey='name'
            axisLine={{ stroke: '#a3abb7' }}
            tickFormatter={formatXAxis}
            tickLine={false}
            tickMargin={10}
            tick={{
              fontSize: '13px',
            }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickMargin={12}
            width={40}
            tick={{
              fontSize: '13px',
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          {datasets
            .filter(({ dataKey }) => selectedDatasets.includes(dataKey))
            .map(({ dataKey, stroke, fill }) => (
              <React.Fragment key={dataKey}>
                <Area
                  type='monotone'
                  dataKey={dataKey}
                  stroke={stroke}
                  fill={`url(#${fill})`}
                />
                <Line type='monotone' dataKey={dataKey} stroke={stroke} />
              </React.Fragment>
            ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DashChart;
