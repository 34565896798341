import React from 'react';
import { formatDistanceToNow, parseISO, isAfter } from 'date-fns';
import { ReactComponent as ViewIcon } from '../../icons/dashboard-view.svg';
import { ReactComponent as ClickIcon } from '../../icons/dashboard-click.svg';
import { ReactComponent as ComputerIcon } from '../../icons/dashboard-computer.svg';
import './warmth-factors.css';

const WARMTH_FACTOR_SCORES = {
  repeatedLinkClicks: {
    score: 20,
    icon: ClickIcon,
    singleMessage: (name, events) => {
      const latestClick = new Date(
        events.repeatedLinkClicks[events.repeatedLinkClicks.length - 1],
      );
      const count = events.repeatedLinkClicks.length;
      return `${name} clicked multiple links${count > 1 ? ` (${count})` : ''}, last ${formatDistanceToNow(latestClick)} ago`;
    },
    groupMessage: (name, count, events) => {
      const latestClick = new Date(
        events.repeatedLinkClicks[events.repeatedLinkClicks.length - 1],
      );
      const eventCount = events.repeatedLinkClicks.length;
      return `${name} and ${count} others clicked multiple links${eventCount > 1 ? ` (${eventCount})` : ''}, last ${formatDistanceToNow(latestClick)} ago`;
    },
  },
  repeatedEmailOpens: {
    score: 20,
    icon: ViewIcon,
    singleMessage: (name, events) => {
      const timestamps = events.repeatedEmailOpens;
      const firstOpen = new Date(timestamps[0]);
      const lastOpen = new Date(timestamps[timestamps.length - 1]);
      const daysDiff = lastOpen - firstOpen;
      const count = timestamps.length;
      return `${name} opened emails multiple times${count > 1 ? ` (${count})` : ''} across ${Math.floor(daysDiff / (1000 * 60 * 60 * 24)) + 1} days`;
    },
    groupMessage: (name, count, events) => {
      const timestamps = events.repeatedEmailOpens;
      const firstOpen = new Date(timestamps[0]);
      const lastOpen = new Date(timestamps[timestamps.length - 1]);
      const daysDiff = lastOpen - firstOpen;
      const eventCount = timestamps.length;
      return `${name} and ${count} others opened multiple times${eventCount > 1 ? ` (${eventCount})` : ''} across ${Math.floor(daysDiff / (1000 * 60 * 60 * 24)) + 1} days`;
    },
  },
  oldEmailOpened: {
    score: 15,
    icon: ViewIcon,
    singleMessage: (name, events) => {
      const latestOpen = new Date(
        events.oldEmailOpens[events.oldEmailOpens.length - 1],
      );
      return `${name} read an old email ${formatDistanceToNow(latestOpen)} ago`;
    },
    groupMessage: (name, count, events) => {
      const latestOpen = new Date(
        events.oldEmailOpens[events.oldEmailOpens.length - 1],
      );
      const eventCount = events.oldEmailOpens.length;
      return `${name} and ${count} others read old emails${eventCount > 1 ? ` (${eventCount})` : ''}, last ${formatDistanceToNow(latestOpen)} ago`;
    },
  },
  repeatedWebsiteVisits: {
    score: 15,
    icon: ComputerIcon,
    singleMessage: (name, events) => {
      const latestVisit = new Date(
        events.repeatedWebsiteVisits[events.repeatedWebsiteVisits.length - 1],
      );
      const count = events.repeatedWebsiteVisits.length;
      return `${name} visited your website multiple times${count > 1 ? ` (${count})` : ''}, last ${formatDistanceToNow(latestVisit)} ago`;
    },
    groupMessage: (name, count, events) => {
      const latestVisit = new Date(
        events.repeatedWebsiteVisits[events.repeatedWebsiteVisits.length - 1],
      );
      const eventCount = events.repeatedWebsiteVisits.length;
      return `${name} and ${count} others visited multiple times${eventCount > 1 ? ` (${eventCount})` : ''}, last ${formatDistanceToNow(latestVisit)} ago`;
    },
  },
  potentialForwards: {
    score: 10,
    icon: ViewIcon,
    singleMessage: (name, events) => {
      const latestForward = new Date(
        events.potentialForwards[events.potentialForwards.length - 1],
      );
      return `${name} forwarded internally ${formatDistanceToNow(latestForward)} ago`;
    },
    groupMessage: (name, count, events) => {
      const latestForward = new Date(
        events.potentialForwards[events.potentialForwards.length - 1],
      );
      const eventCount = events.potentialForwards.length;
      return `${name} and ${count} others forwarded${eventCount > 1 ? ` (${eventCount})` : ''}, last ${formatDistanceToNow(latestForward)} ago`;
    },
  },
  organicWebsiteVisit: {
    score: 10,
    icon: ComputerIcon,
    singleMessage: (name, events) => {
      const latestVisit = new Date(
        events.organicWebsiteVisits[events.organicWebsiteVisits.length - 1],
      );
      return `${name} visited organically ${formatDistanceToNow(latestVisit)} ago`;
    },
    groupMessage: (name, count, events) => {
      const latestVisit = new Date(
        events.organicWebsiteVisits[events.organicWebsiteVisits.length - 1],
      );
      const eventCount = events.organicWebsiteVisits.length;
      return `${name} and ${count} others found organically${eventCount > 1 ? ` (${eventCount})` : ''}, last ${formatDistanceToNow(latestVisit)} ago`;
    },
  },
  emailOpened: {
    score: 5,
    icon: ViewIcon,
    singleMessage: (name, events) => {
      const latestOpen = new Date(
        events.emailOpens[events.emailOpens.length - 1],
      );
      return `${name} last opened your email ${formatDistanceToNow(latestOpen)} ago`;
    },
    groupMessage: (name, count, events) => {
      const latestOpen = new Date(
        events.emailOpens[events.emailOpens.length - 1],
      );
      const eventCount = events.emailOpens.length;
      return `${name} and ${count} others last opened${eventCount > 1 ? ` (${eventCount})` : ''}, last ${formatDistanceToNow(latestOpen)} ago`;
    },
  },
  linkClicked: {
    score: 5,
    icon: ClickIcon,
    singleMessage: (name, events) => {
      const latestClick = new Date(
        events.linkClicks[events.linkClicks.length - 1],
      );
      return `${name} clicked a link ${formatDistanceToNow(latestClick)} ago`;
    },
    groupMessage: (name, count, events) => {
      const latestClick = new Date(
        events.linkClicks[events.linkClicks.length - 1],
      );
      const eventCount = events.linkClicks.length;
      return `${name} and ${count} others clicked${eventCount > 1 ? ` (${eventCount})` : ''}, last ${formatDistanceToNow(latestClick)} ago`;
    },
  },
};

const WarmthFactors = ({ prospects, completedTime }) => {
  const getWarmthFactors = () => {
    const factors = [];
    const completedDate = completedTime ? new Date(completedTime) : null;

    // Get all factors across all prospects
    const factorCounts = {};

    prospects.forEach((prospect) => {
      if (!prospect.warmth_factors) return;

      Object.entries(prospect.warmth_factors)
        .filter(
          ([key, value]) =>
            value &&
            key !== 'factorEvents' &&
            key !== 'daysSinceLastEngagement',
        )
        .forEach(([key]) => {
          const events = prospect.warmth_factors.factorEvents;
          if (!events) return;

          // Get the relevant timestamps for this factor type
          let timestamps = [];
          const eventKey =
            key === 'organicWebsiteVisit'
              ? 'organicWebsiteVisits'
              : key === 'emailOpened'
                ? 'emailOpens'
                : key === 'linkClicked'
                  ? 'linkClicks'
                  : key === 'oldEmailOpened'
                    ? 'oldEmailOpens'
                    : key;

          timestamps = events[eventKey] || [];

          // Filter timestamps if we have a completedTime
          if (completedTime) {
            timestamps = timestamps.filter((timestamp) =>
              isAfter(new Date(timestamp), completedDate),
            );
          }

          // Only process if we have timestamps after filtering
          if (timestamps.length > 0) {
            if (!factorCounts[key]) {
              factorCounts[key] = {
                key,
                count: 1,
                prospects: [prospect],
                events: { [eventKey]: timestamps },
                ...WARMTH_FACTOR_SCORES[key],
              };
            } else {
              factorCounts[key].count++;
              factorCounts[key].prospects.push(prospect);
              factorCounts[key].events[eventKey] = [
                ...factorCounts[key].events[eventKey],
                ...timestamps,
              ].sort();
            }
          }
        });
    });

    // Convert factors to display format
    Object.values(factorCounts).forEach((factor) => {
      const firstProspect = factor.prospects[0];
      const otherCount = factor.count - 1;
      const eventKey = Object.keys(factor.events)[0];
      const timestamps = factor.events[eventKey];

      factors.push({
        text:
          factor.count === 1
            ? factor.singleMessage(firstProspect.name, {
                [eventKey]: timestamps,
              })
            : factor.groupMessage(firstProspect.name, otherCount, {
                [eventKey]: timestamps,
              }),
        timestamp: new Date(Math.max(...timestamps.map((t) => new Date(t)))),
      });
    });

    // Sort factors by most recent first
    return factors.sort((a, b) => b.timestamp - a.timestamp);
  };

  const factors = getWarmthFactors();

  if (factors.length === 0) {
    return null;
  }

  return (
    <div className='warmth-factors'>
      {completedTime && <b>Since your activity</b>}
      {factors.map((factor, index) => (
        <div key={index} className='warmth-factor'>
          {factor.text}
        </div>
      ))}
    </div>
  );
};

export default WarmthFactors;
