import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { formatDistanceToNow } from 'date-fns';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as LinkIcon } from '../../icons/link.svg';
import { ReactComponent as WebsiteIcon } from '../../icons/bolt.svg';
import { ReactComponent as ReplyIcon } from '../../icons/reply.svg';
import api from '../../api';
import './engagement-activities.scss';

const ActivityItem = ({ icon: Icon, time, detail, title, prospectName }) => (
  <div className='activity-item'>
    <div className='top-set'>
      <span className='prospect-name'>{prospectName}</span>
      <div className='activity-time'>
        {formatDistanceToNow(new Date(time), { addSuffix: true })}
      </div>
      <div className='activity-content'>
        <Icon />

        {title}
      </div>
    </div>
    {detail && <div className='activity-detail'>{detail}</div>}
  </div>
);

const EngagementActivities = ({ show, onHide, orgId, orgName }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [activities, setActivities] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchActivities = async () => {
      if (!show) return;

      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await api.get(
          `/dashboard/activities?known_org_id=${orgId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setActivities(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching activities:', error);
        setError(error.response?.data?.error || 'Failed to load activities');
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, [show, orgId, getAccessTokenSilently]);

  const allActivities = activities
    ? [
        ...activities.opens.map((item) => ({
          type: 'open',
          icon: EmailIcon,
          time: item.time,
          title: 'Email Opened',
          prospectName: item.prospect_name,
        })),
        ...activities.link_clicks.map((item) => ({
          type: 'link',
          icon: LinkIcon,
          time: item.time,
          detail: item.original_url,
          title: 'Link Clicked',
          prospectName: item.prospect_name,
        })),
        ...activities.website_visits.map((item) => ({
          type: 'website',
          icon: WebsiteIcon,
          time: item.time,
          detail: item.url,
          title: 'Website Visited',
          prospectName: item.prospect_name,
        })),
        ...activities.sent.map((item) => ({
          type: 'sent',
          icon: EmailIcon,
          time: item.time,
          detail: item.subject,
          title: 'Email Sent',
          prospectName: item.prospect_name,
        })),
        ...activities.replies.map((item) => ({
          type: 'reply',
          icon: ReplyIcon,
          time: item.time,
          detail: 'Replied to email',
          prospectName: item.prospect_name,
        })),
      ].sort((a, b) => new Date(b.time) - new Date(a.time))
    : [];

  return (
    <DreamSideModal show={show} handleClose={onHide}>
      <DreamModalInner>
        <DreamModalInner.Header closeButton>
          Activity History for {orgName}
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='engagement-activities'>
            <p className='description'>
              These are the actions taken by your prospect and company. We use
              this to calculate their score; increasing it for actions that show
              interest, and reducing it as time passes without interest.
            </p>

            {loading ? (
              <div className='loading-state'>Loading activities...</div>
            ) : error ? (
              <div className='error-state'>{error}</div>
            ) : allActivities.length === 0 ? (
              <div className='empty-state'>No activities recorded yet</div>
            ) : (
              <div className='activities-list'>
                {allActivities.map((activity, index) => (
                  <ActivityItem
                    key={`${activity.type}-${activity.time}-${index}`}
                    icon={activity.icon}
                    time={activity.time}
                    detail={activity.detail}
                    title={activity.title}
                    prospectName={activity.prospectName}
                  />
                ))}
              </div>
            )}
          </div>
        </DreamModalInner.Body>
      </DreamModalInner>
    </DreamSideModal>
  );
};

export default EngagementActivities;
